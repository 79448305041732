<template>
    <div class="grid w-full gap-1.5">
        <Label
            v-if="label || $slots.default"
            :for="id"
            :class="{'text-destructive': errorMsg}"
        >
            <slot>
                {{ label }}
                <span
                    v-if="required"
                    class="font-medium text-destructive"
                >*</span>
            </slot>
        </Label>
        <Select v-model="inputValue">
            <div class="relative">
                <SelectTrigger
                    :class="{
                        'pr-10': allowClear && inputValue
                    }"
                >
                    <SelectValue :placeholder="placeholder" />
                </SelectTrigger>
                <Button
                    v-if="allowClear && inputValue"
                    variant="ghost"
                    size="icon"
                    class="absolute bottom-0 right-0"
                    @click.prevent="inputValue = ''"
                >
                    <X class="size-4" />
                </Button>
            </div>
            <SelectContent>
                <SelectGroup>
                    <SelectItem
                        v-for="option in options"
                        :key="option?.value ?? option"
                        :value="option?.value?.toString() ?? option"
                    >
                        {{ option?.label ?? option }}
                    </SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
        <slot name="description">
            <InputDescription v-if="description">
                {{ description }}
            </InputDescription>
        </slot>
        <slot name="error">
            <InputError v-if="errorMsg">
                {{ errorMsg }}
            </InputError>
        </slot>
    </div>
</template>

<script lang="ts" setup>
import { X, } from 'lucide-vue-next'
import { useId, } from 'radix-vue'
import { computed, toRefs, } from 'vue'

import { Button, } from '@/shadcn/ui/button'
import { InputDescription, InputError, } from '@/shadcn/ui/input'
import { Label, } from '@/shadcn/ui/label'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue, } from '@/shadcn/ui/select'

const id = useId()

const emit = defineEmits<{
    (e: 'update:modelValue', payload: string | number): void
}>()

interface Props {
    modelValue?: string | number,
    options?: Array<object> | Array<string>,
    placeholder?: string,
    label?: string,
    errorMsg?: string,
    required?: boolean,
    description?: string,
    allowClear?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    placeholder: 'Auswählen...',
    allowClear: true,
})

const { modelValue, } = toRefs(props)

const inputValue = computed({
    get () {
        return modelValue.value?.toString()
    },
    set (newValue) {
        emit('update:modelValue', newValue)
    },
})
</script>
