import { toast, } from '@/shadcn/ui/toast'

export const errorHandling = {
    install (app) {
        app.config.errorHandler = function (err, vm, info) {
            console.error(err, vm, info)
            toast({
                title: 'Error',
                description: err.message,
                variant: 'destructive',
            })
        }
    },
}
