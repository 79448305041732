<template>
    <Head :title="title" />
    <div
        v-cloak
        class="flex"
    >
        <Sidebar
            v-if="$page.props.loggedIn"
            class="hidden lg:flex"
        />
        <div class="flex min-h-screen grow flex-col">
            <Navigation />
            <main>
                <div class="mx-auto p-4">
                    <PageTransition v-if="pageTrans" />
                    <transition
                        enter-active-class="transition-all ease-in-out duration-100"
                        leave-active-class="transition-all ease-in-out duration-100"
                        enter-from-class="opacity-0 translate-y-2"
                        leave-to-class="opacity-0 translate-y-2"
                        mode="out-in"
                    >
                        <slot />
                    </transition>
                </div>
            </main>
            <Footer />
            <Toaster />
        </div>
    </div>
</template>

<script setup lang="ts">
import '@/style/main.css'

import { Head, } from '@inertiajs/vue3'
import { getActivePinia, storeToRefs, } from 'pinia'
import { getCurrentInstance, onMounted, watch, } from 'vue'

import { toast, Toaster, } from '@/shadcn/ui/toast'
import Footer from '@/shared/layout/Footer.vue'
import { useLayoutStore, } from '@/shared/layout/layout'
import Navigation from '@/shared/layout/Navigation.vue'
import Sidebar from '@/shared/layout/Sidebar.vue'
import { usePageTransitionStore, } from '@/shared/pageTransition/pageTransition.js'
import PageTransition from '@/shared/pageTransition/PageTransition.vue'

const pageTransitionStore = usePageTransitionStore(getActivePinia())
const { pageTransition: pageTrans, } = storeToRefs(pageTransitionStore)

const layoutStore = useLayoutStore(getActivePinia())
const { title, } = storeToRefs(layoutStore)

const { proxy:vm, } = getCurrentInstance()
vm.$page && watch(() => vm.$page, (newPage) => {
    if (Object.keys(newPage.props.flashBag).length) {
        Object.keys(newPage.props.flashBag).forEach((key: string) => {
            newPage.props.flashBag[key].forEach((msg: string) => {
                toast({
                    description: msg,
                    variant: key,
                })
            })
        })
    }
})

onMounted(() => {
    let splash = document.getElementById('splash-screen')
    splash.classList.add('hidden')
})
</script>
