<template>
    <Link v-bind="attrs">
        <slot />
    </Link>
</template>

<script setup lang="ts">
import { Link, } from '@inertiajs/vue3'
import { getActivePinia, storeToRefs, } from 'pinia'

import { useDialogStore, } from '@/shared/dialog/dialog'
import { usePageTransitionStore, } from '@/shared/pageTransition/pageTransition.js'

let pageTransitionStore = usePageTransitionStore(getActivePinia())
const { pageTransition, } = storeToRefs(pageTransitionStore)
const dialogStore = useDialogStore(getActivePinia())

const show = function () {
    pageTransition.value = true
    dialogStore.closeDialog()
}
const hide = function () {
    pageTransition.value = false
}

const attrs = {
    onStart: show,
    onFinish: hide,
}
</script>
