<template>
    <footer class="mt-auto bg-muted p-10 text-muted-foreground">
        <div class="flex flex-col gap-6 md:flex-row">
            <div class="flex flex-col items-start gap-2">
                <span>Datenschutz / Impressum</span>
                <Button
                    variant="link"
                    size="link"
                    @click="showAlert1()"
                >
                    Datenschutz
                </Button>
                <Button
                    variant="link"
                    size="link"
                    @click="showAlert2()"
                >
                    Impressum
                </Button>
            </div>
            <div
                v-if="$page.props.loggedIn"
                class="flex flex-col items-start gap-2"
            >
                <span>Kontakt</span>
                <Button
                    variant="link"
                    size="link"
                    @click="showContactForm()"
                >
                    Kontakt
                </Button>
                <Button
                    variant="link"
                    size="link"
                    @click="showReportForm()"
                >
                    Fehler melden
                </Button>
            </div>
            <div class="ms-auto text-end">
                <TransitionLink href="/">
                    <img
                        class="mb-3"
                        src="@assets/images/sinetcon-logo_kl.png"
                        alt="Logo"
                    >
                </TransitionLink>
                <div class="w-full">
                    Bahnhofstrasse 98<br>
                    36341 Lauterbach<br>
                    © {{ year }} SiNetCon GmbH<br>
                    <span v-if="$page.props.loggedIn">
                        Do-Time v{{ packageJson.version }}
                    </span>
                </div>
            </div>
        </div>
    </footer>

    <ContactFormDialog v-if="contactDialogVisible" />
    <ReportFormDialog v-if="reportDialogVisible" />
</template>

<script setup lang="ts">
import { getActivePinia, } from 'pinia'

import ContactFormDialog from '@/modules/home/ContactFormDialog.vue'
import ReportFormDialog from '@/modules/home/ReportFormDialog.vue'
import { Button, } from '@/shadcn/ui/button'
import { toast, } from '@/shadcn/ui/toast'
import { GLOBAL_CONTACT_DIALOG_TYPE, GLOBAL_REPORT_DIALOG_TYPE, useDialogStore, } from '@/shared/dialog/dialog'
import TransitionLink from '@/shared/pageTransition/TransitionLink.vue'
// eslint-disable-next-line import/no-unresolved
import packageJson from '~/package.json'

let dialogStore = useDialogStore(getActivePinia())
const contactDialogVisible = dialogStore.isVisibleComputed(GLOBAL_CONTACT_DIALOG_TYPE)
const reportDialogVisible = dialogStore.isVisibleComputed(GLOBAL_REPORT_DIALOG_TYPE)

function showContactForm () {
    dialogStore.showDialog(null, GLOBAL_CONTACT_DIALOG_TYPE)
}

function showReportForm () {
    dialogStore.showDialog(null, GLOBAL_REPORT_DIALOG_TYPE)
}

const year = (new Date()).getFullYear()

function showAlert1 () {
    toast({
        title: 'Es gibt noch keinen Datenschutz!',
        variant: 'warning',
    })
}

function showAlert2 () {
    toast({
        title: 'Es gibt auch noch kein Impressum...',
        variant: 'destructive',
    })
}
</script>
