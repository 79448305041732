import { defineStore, } from 'pinia'
import { computed, } from 'vue'

import { clone, } from '@/shared/Utils'

export const DELETE_DIALOG_TYPE = 'DELETE'
export const EDIT_DIALOG_TYPE = 'EDIT'
export const CREATE_DIALOG_TYPE = 'CREATE'

export const GLOBAL_CONTACT_DIALOG_TYPE = 'CONTACT'
export const GLOBAL_REPORT_DIALOG_TYPE = 'REPORT'

export const useDialogStore = defineStore('dialog', {
    state () {
        return {
            dialogData: null,
            dialogIdentifier: '',
            close: false,
        }
    },
    getters: {
        isVisibleComputed: (state) => (identifier) => computed(() => (state.dialogIdentifier === identifier)),
        isVisible: (state) => (identifier) => state.dialogIdentifier === identifier,
    },
    actions: {
        showDialog (item, identifier, cb: Function | null = null) {
            this.dialogData = clone(item)
            this.dialogIdentifier = identifier
            this.close = false
            if(cb) {
                cb(this.dialogData)
            }
        },
        closeDialog () {
            if (this.close === true) {
                this.close = false
            }
            this.close = true
        },
    },
})
