<template>
    <nav class="flex items-center justify-between gap-4 p-2 shadow">
        <div class="items-center md:w-1/2">
            <Drawer />

            <H2
                v-if="$page.props.loggedIn"
                class="ms-4"
            >
                {{ title }}
            </H2>
            <ProfilToolLogo
                v-else
                class="ms-2"
            />
        </div>

        <div
            v-if="$page.props.loggedIn"
            class="hidden lg:flex"
        >
            <Avatar />
        </div>
    </nav>
</template>

<script setup lang="ts">
import { getActivePinia, storeToRefs, } from 'pinia'

import Avatar from '@/modules/user/Avatar.vue'
import Drawer from '@/shared/layout/Drawer.vue'
import { useLayoutStore, } from '@/shared/layout/layout'
import ProfilToolLogo from '@/shared/layout/ProfilToolLogo.vue'
import H2 from '@/shared/prose/H2.vue'

const layoutStore = useLayoutStore(getActivePinia())
const { title, } = storeToRefs(layoutStore)
</script>
