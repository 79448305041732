<template>
    <Dialog
        v-model:open="open"
    >
        <DialogContent
            class="max-h-[90dvh] w-full grid-rows-[auto_minmax(0,1fr)_auto] p-0"
            :class="[
                large ? 'max-w-3xl' : 'max-w-xl',
            ]"
        >
            <DialogHeader class="p-6 pb-0">
                <DialogTitle class="flex items-center justify-between">
                    <h1 class="text-2xl">
                        <slot name="title" />
                    </h1>

                    <Button
                        variant="ghost"
                        size="icon"
                        @click="open = false"
                    >
                        <X class="size-6" />
                        <span class="sr-only">Close</span>
                    </Button>
                </DialogTitle>

                <DialogDescription>
                    <slot name="description" />
                </DialogDescription>
            </DialogHeader>

            <div class="grid gap-4 overflow-y-auto px-6 py-4">
                <slot name="body">
                    Hallo
                </slot>
            </div>

            <DialogFooter class="p-6 pt-0">
                <slot name="footer">
                    <Button
                        variant="outline"
                        @click="emit('cancel'); hideDialog()"
                    >
                        Abbrechen
                    </Button>

                    <Button
                        v-if="confirmText"
                        variant="secondary"
                        @click="emit('confirm')"
                    >
                        {{ confirmText }}
                    </Button>
                </slot>
            </DialogFooter>
        </Dialogcontent>
    </Dialog>
</template>

<script setup lang="ts">
import { X, } from 'lucide-vue-next'
import { getActivePinia, storeToRefs, } from 'pinia'
import { onBeforeUnmount, onMounted, ref, watch, } from 'vue'

import { Button, } from '@/shadcn/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, } from '@/shadcn/ui/dialog'
import { useDialogStore, } from '@/shared/dialog/dialog.js'

const emit = defineEmits([ 'cancel', 'confirm', ])
const open = ref(true)
const dialogStore = useDialogStore(getActivePinia())
const { close, } = storeToRefs(dialogStore)

defineProps<{
    confirmText?: string,
    large?: boolean,
}>()

const hideDialog = () => {
    open.value = false

    // after 200ms the backdrop is completely faded out, so we can reset the dialog store
    setTimeout(() => {
        dialogStore.$reset()
    }, 200)
}

watch(open, (value) => {
    if (!value) {
        hideDialog()
    }
})

watch(close, (v) => {
    if (v) {
        hideDialog()
    }
})

onMounted(() => {
    open.value = true

    //setTimeout(() => {
    //    const input = document.querySelector('.modal-box input:first-of-type') as HTMLElement | null
    //    if (input != null) {
    //        input.focus()
    //    }
    //}, 300)
})

onBeforeUnmount(() => {
    hideDialog()
})
</script>
