<template>
    <Sheet v-model:open="open">
        <SheetTrigger as-child>
            <Button
                variant="outline"
                size="icon"
                class="shrink-0 lg:hidden"
            >
                <Menu class="size-5" />
                <span class="sr-only">Toggle navigation menu</span>
            </Button>
        </SheetTrigger>
        <SheetContent
            side="left"
            class="bg-primary-foreground p-0"
        >
            <SheetTitle class="sr-only">
                Navigation menu
            </SheetTitle>
            <SheetDescription class="sr-only">
                Navigation menu
            </SheetDescription>
            <Sidebar class="w-full" />
        </SheetContent>
    </Sheet>
</template>

<script setup lang="ts">
import { useWindowSize, } from '@vueuse/core'
import { Menu, } from 'lucide-vue-next'
import { getCurrentInstance, ref, watch, } from 'vue'

import { Button, } from '@/shadcn/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger, } from '@/shadcn/ui/sheet'
import Sidebar from '@/shared/layout/Sidebar.vue'

const { width, } = useWindowSize()
const open = ref(false)

watch(width, () => {
    if (width.value >= 1024) {
        open.value = false
    }
})

const { proxy: vm, } = getCurrentInstance()

vm.$page && watch(() => vm.$page, () => {
    open.value = false
})
</script>
