import { getActivePinia, storeToRefs, } from 'pinia'
import { getCurrentInstance, watch, } from 'vue'

import { type User, } from '@/app.d'
import { useUserStore, } from '@/modules/user/user'

export function useUserStateSync () {
    let userStore = useUserStore(getActivePinia())
    const { currentUser, } = storeToRefs(userStore)
    const { proxy: vm, } = getCurrentInstance()

    vm.$page && watch(() => vm.$page, (newPage) => {
        if (newPage.props.loggedIn) {
            currentUser.value = newPage.props.user as User
        } else {
            currentUser.value = null
        }
    }, { immediate: true, })

    return {
        user: currentUser,
    }
}
