import '@/shared/registerSw'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { createInertiaApp, } from '@inertiajs/vue3'
import { createPinia, } from 'pinia'
import { createApp, h, } from 'vue'

import initState from '@/shared/layout/initState'
import Layout from '@/shared/layout/Layout.vue'
import { errorHandling, } from '@/shared/utils/errorHandling'

/**
 * Imports the given page component from the page record.
 */
async function resolvePageComponent (name, pages) {
    for (const path in pages) {
        if (name.split('/').every((namePart) => path.includes(namePart))) {
            let { default: page, } = await pages[path]()
            page.layout = page.layout || Layout
            return typeof pages[path] === 'function'
                ? page
                : pages[path]
        }
    }

    throw new Error(`Page not found: ${name}`)
}

// Creates the Inertia app, nothing fancy.
createInertiaApp({
    resolve: (name) => resolvePageComponent(name, import.meta.glob([ './modules/**/*Page.vue', ])),
    setup ({ el, App, props, plugin, }) {
        const pinia = createPinia()
        let app = createApp({ render: () => h(App, props), })
            .use(plugin)
            .use(pinia)
            .use(errorHandling)

        if (import.meta.env.VITE_BUGSNAG_API_KEY) {
            Bugsnag.start({
                apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
                plugins: [ new BugsnagPluginVue(), ],
            })
            const bugsnagVue = Bugsnag.getPlugin('vue')
            app.use(bugsnagVue)
        }
        let { props: initProps, } = props?.initialPage ?? { props: {}, }
        initState(initProps).then(() => {
            app.mount(el)
        })
    },
})
