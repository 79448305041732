<template>
    <button
        class="gap-2"
        :disabled="isLoading"
        @click="doAction"
    >
        <TriangleAlert
            v-if="hasError"
            class="size-5"
        />
        <Loader2
            v-if="isLoading"
            class="size-5"
        />
        <slot />
    </button>
</template>

<script lang="ts" setup>
import { Loader2, TriangleAlert, } from 'lucide-vue-next'
import { ref, toRefs, } from 'vue'

import { toast, } from '@/shadcn/ui/toast'

const props = defineProps({
    action: {
        type: Function,
        default: () => true,
    },
    showError: {
        type: Boolean,
        default: false,
    },
})

const { action, showError, } = toRefs(props)
const isLoading = ref(false)
const hasError = ref(false)

const doAction = () => {
    isLoading.value = true
    hasError.value = false

    action.value()
        .then(() => {
        })
        .catch((e) => {
            if (showError.value) {
                toast({
                    title: 'Fehler',
                    description: e,
                    variant: 'destructive',
                })
            }
            hasError.value = true
        })
        .finally(() => {
            isLoading.value = false
        })
}
</script>
