<template>
    <DropdownMenu>
        <DropdownMenuTrigger>
            <div
                class="flex cursor-pointer flex-row items-center justify-start gap-2"
            >
                <ProfilePicture
                    :image=" user.image ? user.image : null"
                    :fallback-icon="$page.props.loggedIn ? 'bi-person-fill' : 'bi-person'"
                    class="size-10 shrink-0 bg-muted"
                />

                <div class="flex flex-col items-start">
                    <div
                        class="w-full overflow-hidden truncate text-start text-sm font-semibold"
                    >
                        {{ (user.firstName || user.lastName) ? (user.firstName + ' ' + user.lastName) : '' }}
                    </div>
                    <div
                        class="
                            overflow-hidden
                            truncate
                            text-start
                            text-xs
                            text-muted-foreground
                            "
                    >
                        {{ user.email }}
                    </div>
                </div>
                <ChevronDown class="size-4 shrink-0" />
            </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
            <DropdownMenuItem as-child>
                <TransitionLink
                    href="/profile"
                >
                    Profil
                </TransitionLink>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem as-child>
                <TransitionLink
                    href="/logout"
                >
                    Abmelden
                </TransitionLink>
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>

<script setup lang="ts">
import { ChevronDown, } from 'lucide-vue-next'
import TransitionLink from '@/shared/pageTransition/TransitionLink.vue'
import { useUserStateSync, } from '@/modules/user/useUserStateSync'
import ProfilePicture from '@/modules/user/ProfilePicture.vue'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger, } from '@/shadcn/ui/dropdown-menu'

let { user, } = useUserStateSync()
</script>
