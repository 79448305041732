<template>
    <div class="flex min-h-full w-full flex-col gap-y-4 bg-primary-foreground p-2 text-foreground lg:w-52">
        <div class="flex">
            <ProfilToolLogo class="mx-4 my-3" />
        </div>

        <div class="flex flex-col">
            <component
                :is="comp"
                v-for="comp in navItems"
                :key="comp"
            />
        </div>

        <div class="mt-auto grid gap-4 lg:hidden">
            <component
                :is="comp"
                v-for="comp in topItems"
                :key="comp"
            />
        </div>
        <div class="flex lg:hidden">
            <Avatar
                v-if="$page.props.loggedIn"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, } from 'vue'

import Avatar from '@/modules/user/Avatar.vue'
import ProfilToolLogo from '@/shared/layout/ProfilToolLogo.vue'

const navItems = Object.values(import.meta.glob([ '@/modules/**/*NavItem.vue', ]))
    .map((module) => {
        return defineAsyncComponent(module)
    })

const topItems = Object.values(import.meta.glob([ '@/modules/**/*TopItem.vue', ]))
    .map((module) => {
        return defineAsyncComponent(module)
    })
</script>
