<template>
    <SncDialog
        large
    >
        <template #title>
            Kontaktformular
        </template>
        <template #body>
            <form
                novalidate
                class="grid gap-4"
            >
                <TextInput
                    v-model="regarding"
                    :error-msg="hasErrors && formErrors.regarding ? formErrors.regarding : ''"
                    label="Betreff"
                    required
                />
                <TextInput
                    v-model="email"
                    label="Email"
                />
                <TextAreaInput
                    v-model="message"
                    type="textarea"
                    label="Nachricht"
                    :error-msg="hasErrors && formErrors.message ? formErrors.message : ''"
                    required
                />
            </form>
        </template>
        <template #footer>
            <Button
                variant="outline"
                @click="dialogStore.closeDialog()"
            >
                Abbrechen
            </Button>
            <Button
                as-child
                variant="default"
            >
                <Button
                    variant="secondary"
                    :as="ApiButton"
                    :action="sendContactForm"
                >
                    Absenden
                </Button>
            </Button>
        </template>
    </SncDialog>
</template>

<script setup lang="ts">
import { getActivePinia, } from 'pinia'
import { computed, ref, } from 'vue'

import { Button, } from '@/shadcn/ui/button'
import { toast, } from '@/shadcn/ui/toast'
import { Api, } from '@/shared/Api'
import { useDialogStore, } from '@/shared/dialog/dialog.js'
import SncDialog from '@/shared/dialog/Dialog.vue'
import ApiButton from '@/shared/form/ApiButton.vue'
import TextAreaInput from '@/shared/form/TextAreaInput.vue'
import TextInput from '@/shared/form/TextInput.vue'

let dialogStore = useDialogStore(getActivePinia())

const regarding = ref()
const message = ref()
const email = ref()

const formErrors = ref({})
const hasErrors = computed(() => Object.keys(formErrors.value).length)

const sendContactForm = async () => {
    let contactForm = {
        'regarding': regarding.value,
        'message': message.value,
        'email': email.value,
    }
    let msg = 'Die Nachricht wurde gesendet.'
    let response = await Api.post('/contact-form', contactForm)

    if (response.formErrors) {
        formErrors.value = response.formErrors
    } else {
        toast({
            title: msg,
            variant: 'success',
        })
        dialogStore.closeDialog()

        regarding.value = ''
        message.value = ''
        email.value = ''
    }
}
</script>
