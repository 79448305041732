const getViolations = function (response) {
    if (!response.violations) {
        throw new Error('422 Konnte nicht verarbeitet werden.')
    }

    let violations = {}
    response.violations.forEach((v) => {
        if (violations[v.propertyPath]) {
            violations[v.propertyPath].push(v.message)
        } else {
            violations[v.propertyPath] = [ v.message, ]
        }
    })

    return { 'violations': violations, }
}

const _fetch = async function (url: string, data = null, method = 'GET', catchErrors = true) {
    let options = {
        method,
        headers: {
            'Accept': 'application/json',
            'Content-type': method === 'PATCH' ? 'application/merge-patch+json' : 'application/json; charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest',
            'X-PureApi': 'true',
        },
    }
    if (data) {
        options['body'] = JSON.stringify(data)
    }

    const response = await fetch(url, options)
    if (response.status === 204) {
        return {}
    }

    let json = await response.json()
    if (typeof json === 'string') {
        json = JSON.parse(json)
    }

    if (catchErrors) {
        if ([ 401, 400, ].includes(response.status)) {
            throw new Error(json.error ?? 'Zugriff verweigert!')
        }
        if (response.status === 404) {
            throw new Error(json.error ?? 'Nicht gefunden!')
        }
        if (response.status === 415) {
            throw new Error(json.error ?? 'Nicht unterstützter Medientyp!')
        }
        if (response.status === 422) {
            return getViolations(json)
        }
        if (response.status === 500) {
            if (json.title && json.detail) {
                throw new Error(`${json.title}: ${json.detail}`)
            } else {
                throw new Error(json.error ?? 'Interner Serverfehler!')
            }
        }
    }

    return await json
}

const Api = {
    get: (url) => _fetch(url),
    post: (url, data) => _fetch(url, data, 'POST'),
    patch: (url, data) => _fetch(url, data, 'PATCH'),
    put: (url, data) => _fetch(url, data, 'PUT'),
    delete: (url) => _fetch(url, null, 'DELETE'),
}

export {
    Api,
}
