<template>
    <SncDialog
        large
    >
        <template #title>
            Fehler melden
        </template>
        <template #body>
            <form
                novalidate
                class="grid gap-4"
            >
                <TextInput
                    v-model="summary"
                    :error-msg="hasErrors && formErrors.summary ? formErrors.summary : ''"
                    label="Zusammenfassung"
                    required
                />

                <SelectInput
                    v-model="reportType"
                    label="Fehlerart"
                    :options="[
                        { value: 'Fehler', label: 'Fehler' },
                        { value: 'Verbesserungsvorschlag', label: 'Verbesserungsvorschlag' },
                    ]"
                    :allow-clear="false"
                />

                <TextInput
                    v-model="email"
                    label="Email"
                    description="Um bei Nachfragen in Kontakt treten zu können."
                />

                <TextAreaInput
                    v-model="description"
                    :error-msg="hasErrors && formErrors.description ? formErrors.description : ''"
                    label="Beschreibung"
                    type="textarea"
                    required
                />
            </form>
        </template>
        <template #footer>
            <Button
                variant="outline"
                @click="dialogStore.closeDialog()"
            >
                Abbrechen
            </Button>
            <Button
                :as="ApiButton"
                variant="secondary"
                :action="sendReportForm"
            >
                Absenden
            </Button>
        </template>
    </SncDialog>
</template>

<script setup lang="ts">
import { getActivePinia, } from 'pinia'
import { computed, ref, } from 'vue'

import { Button, } from '@/shadcn/ui/button'
import { toast, } from '@/shadcn/ui/toast'
import { Api, } from '@/shared/Api'
import { useDialogStore, } from '@/shared/dialog/dialog.js'
import SncDialog from '@/shared/dialog/Dialog.vue'
import ApiButton from '@/shared/form/ApiButton.vue'
import SelectInput from '@/shared/form/SelectInput.vue'
import TextAreaInput from '@/shared/form/TextAreaInput.vue'
import TextInput from '@/shared/form/TextInput.vue'

const dialogStore = useDialogStore(getActivePinia())

const summary = ref()
const reportType = ref('Fehler')
const email = ref()
const description = ref()

const formErrors = ref({})
const hasErrors = computed(() => Object.keys(formErrors.value).length)

const sendReportForm = async () => {
    let reportForm = {
        'summary': summary.value,
        'reportType': reportType.value,
        'email': email.value,
        'description': description.value,
    }
    let msg = 'Die Nachricht wurde gesendet.'
    let response = await Api.post('/report-form', reportForm)

    if (response.formErrors) {
        formErrors.value = response.formErrors
    } else {
        toast({
            title: msg,
            variant: 'success',
        })
        dialogStore.closeDialog()

        reportType.value = ''
        description.value = ''
        email.value = ''
    }
}
</script>
