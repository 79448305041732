export const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

export function debounce (fn, delay) {
    let timeoutID = null

    return function () {
        clearTimeout(timeoutID)
        let args = arguments
        let that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}

export function stripHtml (value) {
    return (value || '').replace(/<[^>]*>/g, '')
}

const resizeAndCrop = function (img, square = 192, orientation = 1) {

    if(orientation < 1 || orientation > 8) {
        orientation = 1
    }
    let width = img.width
    let height = img.height

    let sourceX = 0
    let sourceY = 0

    // calculate the width and height, constraining the proportions
    if (width > height) {
        // Querformat
        width = Math.round((width *= square / height))
        height = square

        // Bild horizontal zentrieren
        if (width > square) {
            sourceX = (width - square) / 2
            sourceY = 0
        }
    } else {
        // Hochformat
        height = Math.round((height *= square / width))
        width = square

        // Bild vertical zentrieren
        if (height > square) {
            sourceX = 0
            sourceY = (height - square) / 2
        }
    }

    // resize and crop canvas and draw the image data into it
    let resizeCanvas = document.createElement('canvas')
    resizeCanvas.width = width
    resizeCanvas.height = height

    let resizeCtx = resizeCanvas.getContext('2d')
    function drawRotated (degrees, landscape){
        if(degrees === 180) {
            resizeCtx.translate(resizeCanvas.width, resizeCanvas.height) // 180 Querformat / Hochformat
        } else if(degrees === 90 && landscape) {
            resizeCtx.translate(resizeCanvas.width - sourceX, -sourceX) // 90 Querformat
        } else if(degrees === 90 && !landscape) {
            resizeCtx.translate(resizeCanvas.width + sourceY, sourceY) // 90 Hochformat
        } else if(degrees === 270 && landscape) {
            resizeCtx.translate( sourceX, resizeCanvas.height) // 270 Querformat
        } else if(degrees === 270 && !landscape) {
            resizeCtx.translate( -sourceY, resizeCanvas.height - sourceY) // 270 Hochformat
        }
        resizeCtx.rotate(degrees * Math.PI / 180)
    }

    let landscape = sourceX >= sourceY
    switch (orientation) {
    case 3:
    case 4:
        drawRotated(180, landscape)
        break
    case 5:
    case 6:
        drawRotated(90, landscape)
        break
    case 7:
    case 8:
        drawRotated(270, landscape)
        break
    }

    resizeCtx.drawImage(img, 0, 0, width, height)

    // extrahierte zugeschnittene ImageDaten
    let imageData = resizeCtx.getImageData(sourceX, sourceY, width, height)

    // Die extrahierten ImageDaten in ein neues Ziel-Canvas hereinladen
    let cropCanvas = document.createElement('canvas')
    cropCanvas.width = square
    cropCanvas.height = square
    let cropCtx = cropCanvas.getContext('2d')
    cropCtx.putImageData(imageData, 0, 0)

    return cropCanvas
}

export const processFile = function (file, square) {
    // Make new FileReader
    let reader = new FileReader()

    // Convert the file to base64 text
    reader.readAsArrayBuffer(file)

    return new Promise((resolve) => {
        // on reader load somthing...
        reader.onload = (event) => {
            // Extract orientation from EXIF data
            let orientation = (() => {
                let view = new DataView(<ArrayBuffer>event.target.result)
                if (view.getUint16(0, false) != 0xFFD8) {
                    return -2
                }
                let length = view.byteLength, offset = 2
                while (offset < length) {
                    if (view.getUint16(offset + 2, false) <= 8) return -1
                    let marker = view.getUint16(offset, false)
                    offset += 2
                    if (marker == 0xFFE1) {
                        if (view.getUint32(offset += 2, false) != 0x45786966) {
                            return -1
                        }

                        let little = view.getUint16(offset += 6, false) == 0x4949
                        offset += view.getUint32(offset + 4, little)
                        let tags = view.getUint16(offset, little)
                        offset += 2
                        for (let i = 0; i < tags; i++) {
                            if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                                return view.getUint16(offset + (i * 12) + 8, little)
                            }
                        }
                    } else if ((marker & 0xFF00) != 0xFF00) {
                        break
                    } else {
                        offset += view.getUint16(offset, false)
                    }
                }

                return -1
            })()

            // blob stuff
            let blob = new Blob([ event.target.result, ]) // create blob...
            window.URL = window.URL || window.webkitURL
            let blobURL = window.URL.createObjectURL(blob) // and get it's URL

            // helper Image object
            let image = new Image()
            image.src = blobURL

            // have to wait till it's loaded
            image.onload = function () {
                let canvas = resizeAndCrop(image, square, orientation)

                let compressionRate = 0.9
                let base64encoded = canvas.toDataURL(
                    'image/jpeg',
                    compressionRate
                )

                // Kompremiere weiter, wenn dass Bild noch immer zu groß ist
                while (base64encoded.length > 65000) {
                    compressionRate = compressionRate - 0.05 // send it to canvas
                    base64encoded = canvas.toDataURL(
                        'image/jpeg',
                        compressionRate
                    )
                }

                resolve(base64encoded)
            }
        }
    })
}

export class ServiceWorkerRegError extends Error {
    constructor (message) {
        super(message)
        this.name = 'ServiceWorkerRegError'
    }
}
